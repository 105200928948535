const achievementSlider = new Swiper(".achievement-slider .swiper-container", {
    slidesPerView: 'auto',
    navigation: {
        prevEl: '.achievement-slider .swiper-prev',
        nextEl: '.achievement-slider .swiper-next'
    },
    breakpoints: {
        1024: {
            slidesPerView: 2.5,
            spaceBetween: 20
        },
        768: {
            slidesPerView: 2.25,
            spaceBetween: 15
        },
        576: {
            slidesPerView: 1.25,
            spaceBetween: 15
        }
    }
})

export default achievementSlider
