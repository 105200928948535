
const productBrandSlider = new Swiper('.product-brand-slider .swiper-container', {
    slidesPerView: 3,
    spaceBetween: 30,
    navigation: {
        prevEl: '.product-brand-slider .swiper-prev',
        nextEl: '.product-brand-slider .swiper-next'
    },
    autoplay: {
        delay: 3000,
        disableOnInteraction: false
    },
    loop: true,
    breakpoints: {
        1024: {
            spaceBetween: 20,
            slidesPerView: 2.5
        },
        768: {
            spaceBetween: 15,
            slidesPerView: 1.5
        }
    }
})

export default productBrandSlider