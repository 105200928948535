// import {gsap} from "gsap";

export default function homeFullpageInit() {
    // let tl = gsap.timeline()
    if ($(window).width() > 1024) {
        if ($("#pagepiling").length > 0) {
            let anchorLink = [];
            let menuNav = `<nav class='fp-nav'><ul id="menuNav"></ul></nav>`;
            let footer = $('footer')
            let banner = $('.home-banner')
            

            $("#pagepiling").after($(menuNav));
            $("#pagepiling").prepend(banner)
            $("#pagepiling").append(footer)

            $("#pagepiling .tooltip-container").each(function (index) {
                let item = $(this).data("tooltip");
                let li = `<li data-menuanchor="section-${index + 1}"><a href="#section-${index + 1}"><span></span></a><div class='tooltip'>${item}</div></li>`;
                $(li).appendTo($("#menuNav"));
            }); 

            $("#pagepiling .fp-section").each(function (index) {
                let id = 'section-' + (index+1)
                anchorLink.push(id)
            });

            $("#pagepiling").pagepiling({
                sectionSelector: ".fp-section",
                navigation: false,
                anchors: anchorLink,
                menu: "#menuNav",
                normalScrollElements: '.scrollable-content',
                afterRender: function() {
                    $("#menuNav").find('li').eq(0).addClass('active')
                },
                afterLoad: function (anchorLink, index) {
                },
            });
            $('.back-to-top').on("click", function(){
                $.fn.pagepiling.moveTo(1)
            })
        } else {
            $('.back-to-top').on("click", function(){
                $('html, body').animate({scrollTop: 0}, 1000)
            })
        }
    } else {
        $('.back-to-top').on("click", function(){
            $('html, body').animate({scrollTop: 0}, 1000)
        })
    }
}
