export default function dealerTriggerInit() {
    $('.dealer-item-wrap').each(function(){
        let siblings = $('.dealer-item-wrap').not($(this))
        let src = $(this).data("src")
        $(this).on("click", function(){
            siblings.removeClass('active')
            $(this).addClass('active')
            $('#mapIframe').attr('src', src)
        })
    })
};
