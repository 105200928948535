export default function homeProductNavHover() {
    $(".product-nav-item").each(function (index) {
        let _this = $(this);
        _this.mouseenter(function () {
            $(".product-nav-hover-item").eq(index).addClass("show");
        });
        _this.mouseleave(function () {
            $(".product-nav-hover-item").eq(index).removeClass("show");
        });
    });
}
