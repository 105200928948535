const bannerHomeSlider = new Swiper(
        ".banner-slider .swiper-container",
        {
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            },
            speed: 1300,
            effect: "fade",
            fadeEffect: {
                crossFade: true,
            },
            // loop: true,
            pagination: {
                el: ".banner-slider .swiper-pagination",
                clickable: true,
            },
        }
    );


export default bannerHomeSlider