const partnerOtherSlider = new Swiper('.partner-slider-other .swiper-container', {
    slidesPerView: 4,
    spaceBetween: 30,
    navigation: {
        prevEl: '.partner-slider-other .btn-prev',
        nextEl: '.partner-slider-other .btn-next'
    },
    breakpoints: {
        1024: {
            slidesPerView: 3.5,
            spaceBetween: 15
        },
        768: {
            slidesPerView: 3.5,
            spaceBetween: 15
        },
        576: {
            slidesPerView: 1.5,
            spaceBetween: 15
        }
    }
})

export default partnerOtherSlider