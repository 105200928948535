export default function homePartnerTabs() {
    
    $('.partner-slider').each(function(){
        let _this = $(this)
        let swiperContainer = _this.find('.swiper-container')
        let btnPrev = _this.find('.swiper-prev')
        let btnNext = _this.find('.swiper-next')
        let partnerSlider = new Swiper(swiperContainer, {
            slidesPerView: 4,
            slidesPerColumn: 2,
            spaceBetween: 30,
            navigation: {
                prevEl: btnPrev,
                nextEl: btnNext
            },
            breakpoints: {
                1024: {
                    slidesPerView: 3.5,
                    slidesPerColumn: 1,
                    spaceBetween: 15
                },
                768: {
                    slidesPerView: 2.5,
                    slidesPerColumn: 1,
                    spaceBetween: 15
                },
                576: {
                    slidesPerView: 1.5,
                    slidesPerColumn: 1,
                    spaceBetween: 15
                }
            }
        })
    })

    $(".home-partner-tabs").tabslet();

};
