const productDetailSlide = function () {
    var productDetailThumbnail = new Swiper(
        ".product-detail-thumbnail .swiper-container",
        {
            slidesPerView: 4,
            spaceBetween: 20,
            direction: "vertical",
            observeParents: true,
            observer: true,
            slideToClickedSlide: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            breakpoints: {
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 15,
                    direction: "horizontal",
                    freeMode: true,
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                    direction: "horizontal",
                },
            },
        }
    );

    var productDetailImages = new Swiper(
        ".product-detail-images .swiper-container",
        {
            observer: true,
            observeParents: true,
            thumbs: {
                swiper: productDetailThumbnail,
            },
            navigation: {
                prevEl: ".product-detail-thumbnail .swiper-vertical-prev",
                nextEl: ".product-detail-thumbnail .swiper-vertical-next",
            },
            on: {
                slideChange: function () {
                    let activeIndex = this.activeIndex + 1;

                    let nextSlide = $(
                        `.product-detail-thumbnail .swiper-slide:nth-child(${
                            activeIndex + 1
                        })`
                    );
                    let prevSlide = $(
                        `.product-detail-thumbnail .swiper-slide:nth-child(${
                            activeIndex - 1
                        })`
                    );

                    if (
                        nextSlide &&
                        !nextSlide.hasClass("swiper-slide-visible")
                    ) {
                        this.thumbs.swiper.slideNext();
                    } else if (
                        prevSlide &&
                        !prevSlide.hasClass("swiper-slide-visible")
                    ) {
                        this.thumbs.swiper.slidePrev();
                    }
                },
            },
        }
    );
};

const formContactProductDetailJs = function () {
    $("#formImage img").attr("src", $('#hiddenFormImageUrl').val());
    $(".product-contact-form .department input").val($('#hiddenDeparmentValue').val());
};

export { productDetailSlide, formContactProductDetailJs };
