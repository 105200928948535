export default function memberTriggerInit() {
    $(".member-item-trigger").each(function () {
        let _this = $(this);
        let triggerSiblings = $(".member-item-trigger").not(_this);
        let index = _this.data("index");
        let target = $(`.member-detail-item-pc[data-index=${index}]`);
        let targetSiblings = $(".member-detail-item-pc").not(target);
        _this.on("click", function () {
            if ($(window).width() >= 1024) {
                triggerSiblings.removeClass("active");
                _this.addClass("active");
                targetSiblings.hide();
                target.show();
            } else {
                triggerSiblings.removeClass("active");
                _this.addClass("active");
                targetSiblings.slideUp()
                _this.next().slideDown()
            }
        });
        if ($(window).width() < 1024) { 
            target.insertAfter(_this)
        }
    });

    $(".member-item-trigger").eq(0).trigger('click')

}
