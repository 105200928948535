export default function newsSliderInit() {
    $('.product-slider').each(function(){
        let _this = $(this)
        let swiperContainer = _this.find('.swiper-container')
        let btnPrev = _this.find('.swiper-prev')
        let btnNext = _this.find('.swiper-next')
        let productSlider = new Swiper(swiperContainer, {
            slidesPerView: 4,
            spaceBetween: 30,
            navigation: {
                prevEl: btnPrev,
                nextEl: btnNext
            },
            breakpoints: {
                1024: {
                    slidesPerView: 3.5,
                    spaceBetween: 15
                },
                768: {
                    slidesPerView: 2.5,
                    spaceBetween: 15
                },
                576: {
                    slidesPerView: 1.5,
                    spaceBetween: 15
                }
            }
        })
    })
};
