import homeFullpageInit from "./homeFullpageInit";
import headerInit from "./headerInit";
import bannerHomeSlider from "./bannerHome";
import homePartnerTabs from "./homePartnerTabs";
import newsSliderInit from "./newsSliderInit";
import productSliderInit from "./productSliderInit";
import homeShareholderTabs from "./homeShareholderTabs";
import homeProductNavHover from "./homeProductNavHover";
import historySlider from "./historySliderInit";
import achievementSlider from "./achievementSliderInit";
import memberTriggerInit from "./memberTriggerInit";
import partnerOtherSlider from "./partnerOtherSlider";
import dealerTriggerInit from "./dealerTriggerInit";
import productBrandSlider from "./productBrandSliderInit";
import {
	productDetailSlide,
	formContactProductDetailJs,
} from "./productDetail";

loadYoutubeApi();

jQuery(function () {
	headerInit();
	homeFullpageInit();
	homePartnerTabs();
	newsSliderInit();
	homeShareholderTabs();
	if ($(window).width() > 1024) {
		homeProductNavHover();
	}
	// About
	historySlider;
	achievementSlider;
	memberTriggerInit();

	// Partner
	partnerOtherSlider;

	//Dealer
	dealerTriggerInit();

	//Product
	productSliderInit();
	productBrandSlider;

	//Product Detail
	productDetailSlide();
	formContactProductDetailJs();

	// Other stuff
	$(".FirstPage,.LastPage,.BackPage,.NextPage").parent().remove();
	$.fancybox.defaults.parentEl = "form";
	if ($(window).width() < 768) {
		$(".product-sub-nav-mobile").on("click", function () {
			$(this).next().slideToggle();
			$(this)
				.find(".toggle span")
				.toggleClass("fa-chevron-down fa-chevron-up");
		});
	}


	//Form wizard
	try {
		var observerForm = new MutationObserver(function (mutations) {
			mutations.forEach(function (mutation) {
				if (mutation.type === "childList") {
					$(".wrap-form").each(function () {
						let captchaContainer = $(this).find(".frm-captcha");
						let submitContainer = $(this).find(".frm-btnwrap");
						$(captchaContainer).prepend($(submitContainer));
					});
				}
			});
		});

		observerForm.observe(
			document.querySelector(".form-container .ModuleContent > div"),
			{
				childList: true,
			}
		);

		$(".wrap-form").each(function () {
			let captchaContainer = $(this).find(".frm-captcha");
			let submitContainer = $(this).find(".frm-btnwrap");
			$(captchaContainer).prepend($(submitContainer));
		});
	} catch (error) { }
});

function loadYoutubeApi() {
	var tag = document.createElement("script");

	tag.src = "https://www.youtube.com/iframe_api";
	var firstScriptTag = document.getElementsByTagName("script")[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

window.onYouTubeIframeAPIReady = function () {
	initYoutubePlayer();
};

function initYoutubePlayer() {
	$(".yt-video-player").each(function () {
		let _this = $(this);
		let videoId = _this.attr("data-video-id");
		let id = _this.attr("id");
		let player = new YT.Player(id, {
			videoId: videoId,
			playerVars: {
				autoplay: 0,
				showinfo: 0,
				controls: 0,
				disablekb: 0,
				enablejsapi: 1,
				fs: 0,
				iv_load_policy: 3,
				modestbranding: 1,
				playsinline: 1,
				rel: 0,
				mute: 1,
			},
			events: {
				onReady: function (e) {
					e.target.playVideo(0);
					bannerHomeSlider.autoplay.stop();
				},
				onStateChange: function (e) {
					if ((e.data === 0)) {
						bannerHomeSlider.autoplay.start();
					}
				},
			},
		});
	});
}
