import { gsap } from "gsap"

export default function headerInit() {

    const menuTopMapping = new MappingListener({
        selector: ".menu-wrapper",
        mobileWrapper: ".mobile-menu-wrapper",
        mobileMethod: "appendTo",
        desktopWrapper: ".header-util-wrapper",
        desktopMethod: "insertBefore",
        breakpoint: 1025,
    }).watch();

    $(".search-link").on("click", function(e){
        e.preventDefault()
        $(this).find('span').toggleClass('fa-magnifying-glass fa-xmark')
        $('.searchbox').toggleClass('show')
    })

    $('.menu-toggle').on("click", function(e){
        $('.mobile-menu').fadeIn()
        gsap.to(".menu-wrapper", { duration: 0.5, scale: 1, opacity: 1 })
    })

    $('.mobile-menu-overlay').on("click", function(){
        $('.mobile-menu').fadeOut()
        gsap.to(".menu-wrapper", { duration: 0.5, scale: 1.15, opacity: 0 })

    })

}
